<template>
  <div style="height: 100vh; overflow: scroll">
    <div>
      <div class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading...">
        <div id="printDiv" v-show="printShow" ref="init11">
          <TDS
            :printModel="this.$data"
            style="height-max: 1000px"
          ></TDS>
        </div>
        <div class="new-item-head">
          TDS Import {{ titleType }}
          <span
            style="position: absolute; left: 10px; cursor: pointer"
            @click="back">
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            style="
              position: absolute;
              right: 10px;
              cursor: pointer;
              font-weight: 400;
              font-size: 14px;
            "
          >
            <el-dropdown placement v-if="userInfo.username">
              <div>
                <i class="el-icon-user" style="margin-right: 10px"></i>
                {{ userInfo.username }}
                <i class="el-icon-arrow-down"></i>
                <!-- <span class="user-name">语言切换</span> -->
                <el-dropdown-menu class="user-dropdown" slot="dropdown">
                  <el-dropdown-item @click.native="logoutHandle"
                    >Logout</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div
        style="
          background: white;
          margin-top: 40px;
          width: 90%;
          margin-left: 2.5%;
          padding: 40px;
        "
      >
        <center>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px">
          Spec Info
        </div>
        <div style="margin-top: 40px"></div>
          <el-form
            label-position="right"
            label-width="310px"
            style="width: 900px; align: center"
            ref="formModel"
            :model="formModel"
          >
            <el-form-item label="产品编码">
              <el-input
                v-model="formModel.itemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品名称">
              <el-input
                v-model="formModel.productName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="样品编码">
              <el-input
                v-model="formModel.sampleCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品描述">
              <el-input
                v-model="formModel.productDescription"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px">
          Quality Control Test Method
        </div>
        <div style="margin-top: 40px"></div>
        <el-form
          label-position="right"
          label-width="310px"
          style="width: 900px; align: center"
          ref="formModel"
          :model="formModel"
          >
          <el-form-item v-for="(element,index) in methodCodeArr" :key="index" :label="index==0?'测试方法':''" style="float:left;">
            <el-select v-model="element.methodCode" placeholder="choose by Method Code" @change="changeMethod">
              <el-option
                v-for="(item,index) in methodData"
                :key="index"
                :label="item.methodName"
                :value="item.methodCode"
                :style="index==0?'height:70px;':'36px;'">
                <table>
                  <tr v-if="index==0">
                    <td class="mcTdClass"><b>Method Code</b></td>
                    <td class="mcTdClass"><b>Method Name</b></td>
                    <td class="mcTdClass"><b>Usage</b></td>
                  </tr>
                  <tr>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodCode }}</td>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodName }}</td>
                    <td class="mcTdClass" :class="index == methodData.length-1?'mcTdLastClass':''">{{ item.methodUsage }}</td>
                  </tr>
                </table>
              </el-option>
            </el-select>
            <el-button v-if="methodCodeArr.length>1" @click="deleteTestMethod(index)" type="danger" icon="el-icon-delete" circle style="margin-left:10px;"></el-button>
            <el-button v-if="index==0 && userInfo.operateStatus" type="primary" @click="addTestMethod()" style="margin-left:20px;">新增测试方法</el-button>
            
          </el-form-item>
          <div style="margin-left:200px;margin-top:20px;clear:both;">
            <el-button @click="addTestItem()" icon="el-icon-plus">Add New Item</el-button>
          </div>
        </el-form>
        <div style="font-size: 20px; float: left; height: 20px; margin-left: 20px;">
          Customer  Sign Page
        </div>
        <div style="margin-top: 40px;clear:both;"></div>
        <div>
          <el-checkbox v-model="selectSignPage">客户确认页面</el-checkbox>
        </div>
        </center>
        <div style="margin-top: 20px;">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew()">保存</el-button>
          <el-button
            v-if="userInfo.username === 'JamesChen'"
            type="warning"
            @click="dialogVisible = true"
            >审核</el-button>
          <el-button type="primary" icon="el-icon-printer" @click="printPage()">预览 & 打印</el-button>
          <el-button @click="back">返回</el-button>
        </div>
        <el-dialog
          title="Verify"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleDialogClose">
          <el-input
                v-model="formModel.note"
                placeholder="Please Input Note"
              ></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import TDS from "../PrintProductTypeModule/Cn-TDS.vue";
// import $ from 'jquery'

export default {
  methodName: "new-item",
  mixins: [base],
  components: { TDS },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      titleType: "Add",
      formModel: {
        itemCode: "",
        productName: "",
        sampleCode: "",
        productDescription: "",
        testMethod:"",
        note:"",
        language:1,
        confirmed:2,
        customerSignPage:1,
        verifiedBy:"",
        createTime: base.methods.getLocalTime(-7),
        updateTime: base.methods.getLocalTime(-7),
      },
      selectSignPage:true,
      methodData: [],
      methodCodeArr:[
        {
          methodCode:"",
          methodName:"",
          methodUsage:"",
          sop:"",
          specialRequirement:"",
        }
      ],
      methodCode:"",
      conversionInfo:{},
      tdsId:0,
      printShow:false,
      inclNameArr: [],
      commonNameArr: [],
      botanicalNameArr: [],
      form: {
        ordor:"天然植物气味",
        taste:"",
        color:"",
        solventForExtracti:"",
        shelflife: "未开封情况下3年保质期。产品对热敏感。",
        shelflifeNumber:"",
        storage: "避光，密封保存。",
        percentage: "",
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoProductDescription: "",
        BaseInfoCustomProductName: "",
        inciName: "",
        commonName: "",
        botanicalName: "",
        nativeHerbs: "",
        partUsed: "",
        GeneralSpecCommonName: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        GeneralSpecNativeHerbs: "",
        GeneralSpecPartUsed: "",
        GeneralSpecCarrier: "",
        GeneralSpecPreservative: "",
        analysisRangeBioactivesLevel: "",
        analysisTestBioactivesLevel: "",
        analysisRangePh: "3.0-8.0",
        analysisRangeHeavyMetal: "符合USP <231>",
        analysisTestPh: "符合USP <791>",
        analysisRangePesticide: "符合USP <565>",
        analysisTestPesticide: "",
        analysisRangeTotalPlateCount: "100",
        analysisTestTotalPlateCount: "",
        analysisRangeYeastMold: "100",
        analysisMethodBioactivesLevel: "",
        analysisMethodPh: "USP<791>",
        analysisMethodHeavyMetal: "USP<231>",
        analysisMethodPesticide: "USP<565>",
        analysisMethodTotalPlateCount: "100",
        analysisMethodYeastMold: "100",
        specificationArsenic: "1",
        specificationCadmium: "1",
        specificationLead: "1",
        specificationMercury: "1",
        testMethodArsenic: "USP<232>",
        testMethodCadmium: "USP<232>",
        testMethodLead: "USP<232>",
        testMethodMercury: "USP<232>",
        majorConstituentsInNativeHerbs: "",
        genSpecCustomField: "",
        analysisCustomField: "",
        specificationTotalHeavyMetals: '符合USP <231>'
      },
      divHeight:10,
      divList:[],

    };
  },
  computed: {
  },
  watch: {},
  methods: {
    back() {
      this.$router.push({ path: "cn-home"});
    },
    changeMethod(){
      this.methodData.forEach(item=>{
        this.methodCodeArr.forEach(item1=>{
          if(item.methodCode == item1.methodCode){
            item1.methodName = item.methodName
            item1.methodUsage = item.methodUsage
            item1.sop = item.sop
            item1.specialRequirement = item.specialRequirement
          }
        })
      })
      console.log(this.methodCodeArr)
    },
    handleDialogClose(){
      this.dialogVisible = false
    },
    printPage() {
      this.printShow = true;
      var printFileName = ""; 
      if(this.formModel.productName){
        printFileName ="(TDS)) "+ this.formModel.productName;
      }
      document.title = printFileName;
      setTimeout(() => {
        // this.divList=[]
        // let height =$('#printDiv').innerHeight(); //window.getComputedStyle(this.$refs.init11).height; 
        // console.log('height')
        // console.log(height)
        // console.log(parseInt(height))
        // let addT= (842-parseInt(height)%642)/23
        // this.divHeight = parseInt(addT)
        // for(let i=0;i<addT; i++){
        //   this.divList.push({'div':''})
        // }
        // console.log(addT)
        // console.log(this.divHeight)
        printJS({
          printable: "printDiv",
          type: "html",
          style: "@page {margin: 0;} @media print{}",
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    addTestMethod() {
      const link = this.$router.resolve({
        path: 'cn-item-method'
      })
      window.open(link.href, "_blank");
    },
    addTestItem(){
      this.methodCodeArr.push({
          methodCode:"",
          methodName:"",
          methodUsage:"",
          sop:"",
          specialRequirement:"",
        })
    },
    deleteTestMethod(index){
    this.methodCodeArr.splice(index, 1)
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleTds.tdsInfo(params).then((data) => {
        this.loading =false;
        this.formModel = data;
        this.methodCodeArr = JSON.parse(this.formModel.testMethod)
        this.selectSignPage = this.formModel.customerSignPage == 1 ? true : false
        this.$api.handleCnSpec.getCnDetailByCnItemCode(this.formModel.itemCode)
        .then((res) => {
          if (res != null) {
            console.log("getCnDetailByCnItemCode");
            console.log(res);
            this.getBaseInfoDetail(res)
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    async handleNew() {
      this.loading = true;
      this.formModel.customerSignPage = this.selectSignPage ? 1 : 2
      this.formModel.testMethod = JSON.stringify(this.methodCodeArr)
      console.info(this.formModel);
      if (this.formModel.id != null) {
        this.$api.handleTds
          .tdsUpdate(this.formModel)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "保存成功",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.$api.handleTds
          .tdsAdd(this.formModel)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "保存成功",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    previewType(e){
      console.log(e)
    },
    getConverDetailById(params) {
      this.loading = true;
      this.$api.handleConversion.conversionInfo(params).then((data) => {
        this.loading =false;
        this.conversionInfo = data;
        this.formModel.itemCode = this.conversionInfo.newItemCode
        this.formModel.sampleCode = this.conversionInfo.oldItemCode
        this.$api.handleCnSpec.getCnDetailByCnItemCode(this.formModel.itemCode)
        .then((res) => {
          if (res != null) {
            console.log("getCnDetailByCnItemCode");
            console.log(res);
            this.formModel.productName = res.productName;
            this.formModel.productDescription = res.BaseInfoProductDescription;
            this.getBaseInfoDetail(res)
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    getBaseInfoDetail(newVal){
      var jsonValue=JSON.parse(newVal.jsonValue)
      console.log('getBaseInfoDetail=====jsonValue');
      console.log(jsonValue);
      this.form = jsonValue
      this.formModel.productDescription = jsonValue.productDescription;
      if(jsonValue.productType == 'TY-L'){
        this.form.specificationTotalHeavyMetals = '符合USP <231>'
      }
      if(jsonValue.productType == 'TY-P'){
        this.form.color = jsonValue.specificationColor
        this.form.ordor = jsonValue.specificationOrdor
        this.form.taste = jsonValue.specificationTaste
      }
      if(jsonValue.productType == 'TY-EHXTO' || jsonValue.productType == 'TY-SHXTO'){
        this.form.ordor = jsonValue.odor
        this.form.specificationArsenic = jsonValue.testLimitArsenic
        this.form.specificationCadmium = jsonValue.testLimitCadmium
        this.form.specificationLead = jsonValue.testLimitLead
        this.form.specificationMercury = jsonValue.testLimitMercury
      }
      if(jsonValue.productType == 'TY-P' || jsonValue.productType == 'TY-EHXTO' || jsonValue.productType == 'TY-SHXTO'){
        this.form.analysisRangeTotalPlateCount = jsonValue.specificationTotalPlateCount
        this.form.analysisRangeYeastMold = jsonValue.specificationYeastAndMold
      }

      this.form.GeneralSpecCommonName = jsonValue.commonName;
      this.form.GeneralSpecINCIName = jsonValue.inciName;
      this.form.GeneralSpecBotanicalName = jsonValue.botanicalName;

      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");

      console.log("======this.form==========");
      console.log(this.form);
      console.log("======Liquid-baseInfoItemCodeDetail-end==========");
    },
    getTestMethodConfirmCList() {
      this.loading = true;
      this.$api.handleTestMethod.getTestMethodConfirmCList({}).then((data) => {
        this.loading =false;
        console.log("getTestMethodConfirmCList")
        console.log(data)
        this.methodData = data;
      });
    },
    handleConfirm(){
      if (this.tdsId != 0) {
        this.$confirm(
          "Confirm the audit, Continue?",
          "Tips",
          {
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            distinguishCancelAndClose: true,
            type: "warning",
          }
        ).then(() => {
          this.$api.handleTds
          .tdsConfirm({'id':this.tdsId,'note':this.formModel.note})
          .then(() => {
            this.loading = false;
            this.dialogVisible = false
            var that = this
            this.$message({
              type: "success",
              message: "审核成功",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
        });
      }
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
  },
  created() {
    this.getTestMethodConfirmCList()
    let params = { ...this.$route.query };
    if (params.id) {
      this.tdsId = params.id
      this.getDetailById(params.id);
      this.titleType = "Edit";
    } else {
      this.titleType = "Add";
    }
    if(params.convId){
      this.getConverDetailById(params.convId)
    }
  },
};
</script>
<style lang="scss" scoped>
table { text-align: center; border-collapse: collapse;}
.el-select-dropdown__item{
  height: 36px;
}
.mcTdClass{
  width:200px;
  border-left:1px solid #b1b1b1;
  border-top:1px solid #b1b1b1;
  border-right:1px solid #b1b1b1;
}
.mcTdLastClass{
  border-bottom:1px solid #b1b1b1;
}
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
</style>