<template>
  <div class="page" style="text-align: left; padding: 0 45px  45px 45px">
    <table class="page-wrap" cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th>
            <div class="header" id="header" style="width: 100%; height: 100px;color:#c1c1c1;">
              <div style="width: 11%; float: left;padding-top:5px;">
                <img
                  src="../../assets/print_logo.jpg"
                  style="width: 100px; height: 100px"
                />
              </div>
              <div style="width: 89%; float: right">
                <p
                  style="
                    font-size: 28px;
                    font-family: Times New Roman;
                    text-align: center;
                    vertical-align: text-top;
                  ">
                  <b>Draco Natural Products, Inc.</b>
                </p>
                <p
                  style="
                    font-size: 28px;
                    font-family: Times New Roman;
                    text-align: center;
                    vertical-align: text-top;
                  ">
                  <b><u>{{ printModel.formModel.productName }}产品规格</u></b>
                </p>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tr>
        <td id="content">
          <div style="width: 99%">
            <table style="width: 99%">
              <tr v-show="printModel.formModel.productName">
                <td style="width: 25%"><b>产品名称</b></td>
                <td style="width: 75%">
                  {{ printModel.formModel.productName }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.itemCode">
                <td style="width: 25%"><b>产品编码</b></td>
                <td style="width: 75%">
                  {{ printModel.formModel.itemCode }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.sampleCode">
                <td style="width: 25%"><b>样品编码</b></td>
                <td style="width: 75%">
                  {{ printModel.formModel.sampleCode }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.productDescription">
                <td style="width: 25%"><b>产品说明</b></td>
                <td style="width: 75%">
                  {{ printModel.formModel.productDescription }}
                </td>
              </tr>
            </table>

            <div style="height: 10px"></div>
            <div style="font-size: 18px">
              <b>标准规格</b>
            </div>
            <table
              style="width: 98%;border-collapse: collapse;"
              cellspacing="0"
              class="tab"
            >
              <thead>
                <tr style="text-align: left;border-bottom:2px solid gray">
                  <th v-show="printModel.commonNameArr.length > 0">
                    通用名/其他名称
                  </th>
                  <th v-show="printModel.inclNameArr.length > 0">INCI名称</th>
                  <th v-show="printModel.botanicalNameArr.length > 0">植物名称</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="i" v-for="(tag, i) in maxArr">
                  <td v-show="i + 1 <= printModel.commonNameArr.length">
                    {{ printModel.commonNameArr[i] }}
                  </td>
                  <td v-show="i + 1 <= printModel.inclNameArr.length">
                    {{ printModel.inclNameArr[i] }}
                  </td>
                  <td v-show="i + 1 <= printModel.botanicalNameArr.length">
                    {{ printModel.botanicalNameArr[i] }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table style="width: 99%">
              <tbody>
                <tr v-show="printModel.form.color">
                  <td style="width: 30%"><b>颜色</b></td>
                  <td style="width: 35%">
                    <span>{{ printModel.form.color }}</span>
                  </td>
                  <td style="width: 35%"></td>
                </tr>
                <tr v-show="printModel.form.ordor">
                  <td style="width: 30%"><b>气味</b></td>
                  <td style="width: 35%">
                    <span>{{ printModel.form.ordor }}</span>
                  </td>
                  <td style="width: 35%"></td>
                </tr>

                <tr v-show="printModel.form.taste">
                  <td style="width: 30%"><b>其他成分</b></td>
                  <td style="width: 35%">
                    <span>{{ printModel.form.taste }}</span>
                  </td>
                  <td style="width: 35%"></td>
                </tr>
              </tbody>
              <div style="height: 10px"></div>
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <b><u>分析</u></b>
                  </td>
                  <td style="width: 35%">
                    <b><u>产品规格</u></b>
                  </td>
                  <td style="width: 35%">
                    <b><u>测试方法</u></b>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr v-show="printModel.form.analysisRangePh">
                  <td style="width: 30%">pH</td>
                  <td style="width: 35%">
                    {{ printModel.form.analysisRangePh }}
                  </td>
                  <td style="width: 35%">USP&lt;791&gt;</td>
                </tr>
                <tr v-show="printModel.form.specificationTotalHeavyMetals">
                  <td style="width: 30%"><b>重金属</b></td>
                  <td style="width: 35%" v-if="printModel.form.specificationTotalHeavyMetals=='符合USP <231>'"> {{ printModel.form.specificationTotalHeavyMetals }}</td>
                  <td style="width: 35%" v-else>
                    &lt;{{ printModel.form.specificationTotalHeavyMetals }} ppm
                  </td>
                  <td style="width: 35%">USP&lt;231&gt;</td>
                </tr>
                <tr v-if="printModel.form.specificationArsenic">
                  <td style="width:30%"><b>砷</b></td>
                  <td style="width:35%">
                    &lt;{{ printModel.form.specificationArsenic }} ppm
                  </td>
                  <td style="width:35%">{{ printModel.form.testMethodArsenic }}</td>
                </tr>
                <tr v-if="printModel.form.specificationCadmium">
                  <td style="width:30%"><b>镉</b></td>
                  <td style="width:35%">
                    &lt;{{ printModel.form.specificationCadmium }} ppm
                  </td>
                  <td style="width:35%">{{ printModel.form.testMethodCadmium }}</td>
                </tr>
                <tr v-if="printModel.form.specificationLead">
                  <td style="width:30%"><b>铅</b></td>
                  <td style="width:35%">
                    &lt;{{ printModel.form.specificationLead }} ppm
                  </td>
                  <td style="width:35%">{{ printModel.form.testMethodLead }}</td>
                </tr>
                <tr v-if="printModel.form.specificationMercury">
                  <td style="width:30%"><b>汞</b></td>
                  <td style="width:35%">
                    &lt;{{ printModel.form.specificationMercury }} ppm
                  </td>
                  <td style="width:35%">{{ printModel.form.testMethodMercury }}</td>
                </tr>
                <tr>
                  <td style="width: 30%"><b>农药残留</b></td>
                  <td style="width: 35%">
                    <span t-field="o.standard2">符合USP &lt;565&gt;</span>
                  </td>
                  <td style="width: 35%">USP&lt;565&gt;</td>
                </tr>
                <tr v-if="printModel.form.analysisRangeTotalPlateCount">
                  <td style="width: 30%"><b>细菌总数</b></td>
                  <td style="width: 35%">
                    &lt;{{ printModel.form.analysisRangeTotalPlateCount }} cfu/g
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
                <tr v-if="printModel.form.analysisRangeYeastMold">
                  <td style="width: 30%"><b>酵母和霉菌</b></td>
                  <td style="width: 35%">
                    &lt;{{
                      printModel.form.specificationYeastAndMold
                    }} cfu/g
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
                <tr v-if="printModel.form.specificationEcoli">
                  <td style="width: 30%"><b>大肠杆菌</b></td>
                  <td style="width: 35%">
                    {{ printModel.form.specificationEcoli
                    }}<span
                      v-if="
                        printModel.form.specificationEcoli != '阴性' &&
                          printModel.form.specificationEcoli.toLowerCase() !=
                            'negative'
                      "
                    >
                      cfu/g</span
                    >
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
                <tr v-if="printModel.form.specificationSalmonella">
                  <td style="width: 30%"><b>沙门氏菌</b></td>
                  <td style="width: 35%">
                    {{ printModel.form.specificationSalmonella
                    }}<span
                      v-if="
                        printModel.form.specificationSalmonella != '阴性' &&
                          printModel.form.specificationSalmonella.toLowerCase() !=
                            'negative'
                      "
                    >
                      cfu/g</span
                    >
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
                <tr v-if="printModel.form.specificationStaphAureus">
                  <td style="width: 30%"><b>金色葡萄球菌</b></td>
                  <td style="width: 35%">
                    {{ printModel.form.specificationStaphAureus
                    }}<span
                      v-if="
                        printModel.form.specificationStaphAureus != '阴性' &&
                          printModel.form.specificationStaphAureus.toLowerCase() !=
                            'negative'
                      "
                    >
                      cfu/g</span
                    >
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
                <tr v-if="printModel.form.testMethodTotalColiforms">
                  <td style="width: 30%"><b>大肠菌群</b></td>
                  <td
                    style="width: 35%"
                    v-if="
                      !printModel.form.testMethodTotalColiforms ||
                        printModel.form.testMethodTotalColiforms.toLowerCase() ==
                          'negative' ||
                        printModel.form.testMethodTotalColiforms.toLowerCase() ==
                          '阴性'
                    "
                  >
                    {{ printModel.form.testMethodTotalColiforms }}
                  </td>
                  <td style="width: 35%" v-else>
                    &lt;{{ printModel.form.testMethodTotalColiforms }} MPN/g
                  </td>
                  <td style="width: 35%">FDA-BAM</td>
                </tr>
              </tbody>
            </table>

            <div style="height: 10px"></div>
            <table style="width: 99%">
              <tbody>
                <tr>
                  <td style="width: 30%; font-size: 18px">
                    <b><u>其他</u></b>
                  </td>
                  <td style="width: 70%" />
                </tr>
                <tr v-show="printModel.form.shelflife">
                  <td style="width: 30%"><b>保质期</b></td>
                  <td style="width70%">
                    <span t-field="o.shelf_life" />{{ printModel.form.shelflife }}
                  </td>
                </tr>

                <tr v-if="printModel.form.storage">
                  <td style="width: 30%"><b>储存条件</b></td>
                  <td style="width70%">
                    <span t-field="o.storage" />{{ printModel.form.storage }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="clear: both"></div>
            <div style="height: 20px"></div>
            <div style="font-size: 18px">
              <b><u>注释</u></b>
            </div>
            <div style="font-size: 11px;font-family: Times New Roman;margin-top: 10px;">
              <div>
                <span><i>* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i></span>
              </div>
              <div style="height: 10px"></div>
              <div v-if="printModel.form.isShowNotes2">
                <span><i>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i></span>
              </div>
              <div style="height: 10px" v-if="printModel.form.isShowNotes2"></div>
              <div>
                <span><i>* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。</i></span>
              </div>
              <div style="height: 10px"></div>
              <div>
                <span><i>* 如果产品中含水量低于50%，则产品的pH值必须在1%产品的水溶液中测定。</i></span>
              </div>
              <p v-for="(item, index) in printModel.newNoteArr" :key="index">
                * {{ item.content }}
              </p>
              <!-- <div style="height: 10px"></div>
              <div>
                <b
                  ><u
                    >Disclaimer: The product specifications, limits, test methods,
                    and information contained on this document are accurate to the
                    best of our knowledge based upon general industry information,
                    available scientific data, and customer requests. These values
                    are not intended to be used as specifications for a finished
                    product and should be used only at the sole discretion of the
                    purchaser. According to GMP regulations, the test results of the
                    product must be independently verified by the purchasing party.
                    The appropriate use of this product is the sole responsibility
                    of the user or the purchasing party.</u
                  ></b
                >
              </div> -->
            </div>
            <div style="height: 10px"></div>
            <div style="font-size: 18px">
              <b>检验规则</b>
            </div>
            <div style="height: 10px"></div>
            <div>
              <p style="text-align: center;">产品检验分为出厂检验和型式检验。</p>
              <p>1、出厂检验</p>
              <p style="text-indent: 2em;font-size:14px;">
                每批产品由质检部门检验合格，并出具合格证才能出厂。
              </p>
              <p>1.1 组批与抽样</p>
              <p style="text-indent: 2em;font-size:14px;">
                出厂检验以总混合后的产品为一个批号每批产品随机抽样2件，并将其分成三组。
              </p>
              <p>1.2 出厂检验项目</p>
              <p style="text-indent: 2em;font-size:14px;">
                出厂检验项目为：外观，pH, 重金属， 农残，菌落总数、大肠菌群、霉菌。
              </p>
              <p>2、判定规则</p>
              <p style="text-indent: 2em;font-size:14px;">
                检验结果（除微生物指标外）如有不合格，允许在同批产品中加倍抽样，对不合格项进行复验，复验仍
              </p>
              <p>3、判定规则</p>
              <p>3.1 标志</p>
              <p style="text-indent: 2em;font-size:14px;">
                产品销售包装的标志、图示标示按公司标准统一制作。
              </p>
              <p>3.2 包装</p>
              <p style="text-indent: 2em;font-size:14px;">
                包装容器应该用干燥、清洁、无异味以及不影响品质的材料制成。包装要牢固、密封、防潮，能保护品质。包装材料应易回收、易降解。
              </p>
              <p>3.3 运输</p>
              <p style="text-indent: 2em;font-size:14px;">
                运输工具必须清洁、干燥、无异味、无污染，运输中应防雨、防潮、防曝晒、放污染，严禁与可能污染
                其品质的货物混装运输。
              </p>
              <p>3.4 贮存</p>
              <p style="text-indent: 2em;font-size:14px;">
                产品应贮存在清洁、干燥、阴凉、通风、无异味的专用仓库中。
              </p>
              <p>4、保质期</p>
              <p style="text-indent: 2em;font-size:14px;">
                产品在本规格规定的贮存条件下， 自生产日期起，保质期为12个月。
              </p>
            </div>
            <div style="height: 10px"></div>
            <div style="font-size: 18px" v-if="printModel.methodCodeArr.length>0 && printModel.methodCodeArr[0].methodCode">
              <b>附件：质量控制测试方法</b>
            </div>
            <table style="width: 99%" v-if="printModel.methodCodeArr.length>0 && printModel.methodCodeArr[0].methodCode">
              <tbody v-for="(item, index) in printModel.methodCodeArr" :key="index">
                <tr>
                  <td style="width:6%;">{{ index + 1 }}.</td>
                  <td style="width:47%;">{{ item.methodCode }}</td>
                  <td style="width:47%;">{{ item.methodName }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="2">{{ item.sop }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="2">{{ item.specialRequirement }}</td>
                </tr>
              </tbody>
            </table>
            <p></p>
            <p></p>
            <div v-if="printModel.selectSignPage">
              <div style="clear: both; height: 50px;text-align: center;">
                <b>客户确认</b>
              </div>
              <div style="clear: both; height: 50px">
                <div style="float: left; width: 350px">
                  <div
                    style="float: left; height: 20px; width: 100px; text-align: right"
                  >
                    公司名称
                  </div>
                  <div
                    style="
                  border-bottom: 1px solid black;
                  width: 200px;
                  float: left;
                  height: 20px;
                "
                  ></div>
                </div>
                <div style="float: left">
                  <div
                    style="float: left; height: 20px; width: 100px; text-align: right"
                  >
                    签署代表
                  </div>
                  <div
                    style="
                  border-bottom: 1px solid black;
                  width: 200px;
                  float: left;
                  height: 20px;
                "
                  ></div>
                </div>
              </div>
              <div style="clear: both; height: 50px">
                <div style="float: left; width: 350px">
                  <div
                    style="float: left; height: 20px; width: 100px; text-align: right"
                  >
                    签名/盖章
                  </div>
                  <div
                    style="
                  border-bottom: 1px solid black;
                  width: 200px;
                  float: left;
                  height: 20px;
                "
                  ></div>
                </div>
                <div style="float: left">
                  <div
                    style="float: left; height: 20px; text-align: right; width: 100px"
                  >
                    签署日期
                  </div>
                  <div
                    style="
                  border-bottom: 1px solid black;
                  width: 200px;
                  float: left;
                  height: 20px;
                "
                  ></div>
                </div>
              </div>
            </div>
            <!-- <div :style="'height:'+printModel.divHeight+'cm;'"> -->
            <!-- <div v-for="(item, index) in printModel.divList" :key="index">
              Disclaimer
            </div> -->
          </div>
        </td>
      </tr>
      <tfoot style="width:100%;">
        <tr>
          <td>
            <div style="width:100%;color:#c1c1c1;padding-bottom:25px;font-size:14px;">
              <u>Disclaimer: The product specifications, limits, test methods,
              and information contained on this document are accurate to the
              best of our knowledge based upon general industry information,
              available scientific data, and customer requests. These values
              are not intended to be used as specifications for a finished
              product and should be used only at the sole discretion of the
              purchaser. According to GMP regulations, the test results of the
              product must be independently verified by the purchasing party.
              The appropriate use of this product is the sole responsibility
              of the user or the purchasing party.</u>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import base from "@/common/base.js";
export default {
  name: "TestMethod",
  mixins: [base],
  props: ["printModel"],
  data() {
    return {};
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.botanicalNameArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    }
  },
  methods: {},
  created() {},
  mounted() {
    console.log("====printModel====");
    console.log(this.printModel);
  }
};
</script>
<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>
